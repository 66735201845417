<template>
	<div class="my-expense-mobile">
		<div class="cursor-pointer title" @click="action === 1 && !analyzingImage ? goForce() : ''">
			<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
				<span v-if="action === 1 && !analyzingImage"><component :is="getLucideIcon('ArrowLeft')" color="#FFFFFF" :size="26" /></span>{{ captionTitle }}
			</h1>
		</div>
		<span v-if="analyzingImage">
			<loading :active.sync="analyzingImage" loader="bars" is-full-page />
		</span>
		<div class="form" :class="{ 'mt-16': $isPwa() }">
			<b-form @submit="submitForm">
				<div class="d-flex justify-content-start sub-title">
					{{ FormMSG(8, 'Attach Receipt') }}
				</div>
				<div class="expense-comment" v-if="expense.comment && expense.comment.length > 0">
					<p class="px-2">
						{{ expense.comment }}
					</p>
				</div>
				<b-form-group :label="FormMSG(9, 'Ticket Reference')" label-for="expense-ticket-ref" :label-cols="12">
					<b-form-input id="expense-ticket-ref" type="text" v-model="expense.id" disabled />
					<div class="info-message">
						<span><component :is="getLucideIcon('Info')" :size="16" /></span>
						<span class="label">{{ FormMSG(10, 'Write down this number in your Receipt.') }}</span>
					</div>
				</b-form-group>
				<b-form-group :label="FormMSG(39, 'Select Person')" label-for="new-expense_ticket-ref" v-if="useComponent">
					<b-form-select size="md" v-model="selectedUser" :options="users" value-field="value" text-field="name" :disabled="isUpdate"></b-form-select>
				</b-form-group>
				<b-row>
					<b-col>
						<CapturesPackage
							can-upload-pdf
							multiple
							preview-mode="button"
							parent-type="expense_item"
							parent-sub-type="ticket"
							:parent-id="expense.id"
							:pictures="expense.images"
							:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
							@change="handleImgsTaken"
							@upload-image:loading="handleUploadImgStatus"
						/>
					</b-col>
				</b-row>
				<div class="alert alert-danger mt-4" role="alert" v-if="errorOcr">
					<div class="d-flex align-items-center" style="color: #721c24">
						<div class="pr-3" style="font-size: 1.5rem"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
						<div class="mt-0">
							{{ FormMSG(33, 'An error was occured during analyzing data or no billing information detected in this image.') }}
						</div>
					</div>
				</div>
				<div class="divider pt-3"></div>
				<div class="sub-title">
					{{ FormMSG(12, 'General Information') }}
				</div>
				<div class="mt-2">
					<label for="date-input">{{ FormMSG(13, 'Choose a date') }}</label>
					<b-input-group>
						<b-form-input id="date-input" v-model="dateFormatted" type="text" readonly @click="handleDateInput"></b-form-input>
						<b-input-group-append>
							<b-form-datepicker
								id="ticket-date-picker"
								v-model="expense.date"
								button-only
								right
								:locale="currentLang"
								aria-controls="date-input"
								@context="onContext"
								close-button
							></b-form-datepicker>
						</b-input-group-append>
					</b-input-group>
				</div>
				<b-form-group :label="FormMSG(14, 'Description')" label-for="expense-description" :label-cols="12" class="mt-3">
					<b-form-textarea
						id="expense-description"
						v-model="$v.expense.description.$model"
						rows="3"
						:class="{ 'is-invalid': $v.expense.description.$error }"
					/>
					<div v-if="$v.expense.description.$error" class="invalid-feedback">
						{{ FormMSG(25, 'Please, length should be at least') }}&nbsp;{{ minLengthDescription }}
					</div>
				</b-form-group>
				<b-row>
					<b-col>
						<b-form-group :label="FormMSG(42, 'VAT')" label-for="type-of-vat">
							<v-select
								v-model="vatType"
								label="text"
								:options="taxOptions"
								:reduce="(option) => option.value"
								:get-option-label="(option) => (option.item ? option.item.factor : '')"
								:placeholder="FormMSG(43, 'Select a VAT')"
								:selectable="selectableItem"
								@input="handleVatChange"
							>
								<template #option="option">
									<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
									<div class="text-color ml-3" v-else>{{ option.text }} - {{ option.item.name }} ({{ option.item.factor }}%)</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<b-form-group :label="FormMSG(15, 'Total Amount')" :label-cols="12">
							<b-input-group>
								<b-form-input
									type="number"
									step="0.01"
									placeholder="0"
									v-model="$v.expense.amountTotal.$model"
									:class="{ 'is-invalid': isInvalidAmountTotal || $v.expense.amountTotal.$error }"
									@input="handleAmountTotalChange"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
								<div v-if="isInvalidAmountTotal || $v.expense.amountTotal.$error" class="invalid-feedback">
									{{
										isInvalidAmountTotal
											? FormMSG(44, 'Must be greater than VAT amount')
											: $v.expense.amountTotal.$error
											? FormMSG(4510, 'Please, value must be other than 0')
											: ''
									}}
								</div>
							</b-input-group>
							<!-- <div class="info-message" @click="addVat">
								<span v-if="!showVat && expense.amountVat === 0"><plus-circle :size="16" /></span>
								<span v-else><x-circle :size="16" /></span>
								<span class="label">{{ FormMSG(16, 'Add Vat') }}</span>
							</div> -->
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group :label="FormMSG(46, 'VAT amount')" :label-cols="12">
							<b-input-group>
								<b-form-input
									type="number"
									step="0.01"
									placeholder="0"
									min="0"
									v-model="expense.amountVat"
									:class="isInvalidAmountTotal ? 'is-invalid' : ''"
									:disabled="vatAmountDisabled"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<div v-if="isInvalidAmountTotal" class="invalid-feedback">
								{{ FormMSG(47, 'Must be smaller than total amount') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group :label="FormMSG(18, 'Type of paiement')" v-slot="{ ariaDescribedby }" class="pt-1">
					<b-form-radio-group
						class="paiement-type"
						size="lg"
						:aria-describedby="ariaDescribedby"
						:options="optionPaiementTypes"
						v-model="expense.paiementType"
						name="payment-type"
						stacked
					/>
				</b-form-group>
				<div class="divider pt-1"></div>
				<div class="sub-title">
					{{ FormMSG(19, 'Expense Details') }}
				</div>
				<b-row class="mt-2">
					<b-col cols="12">
						<department-selector
							:edit-data="expense"
							:is-submitted="isSubmitted"
							:is-new="isNew"
							@change="handleDepartmentAndCatChange"
							@department:selector:invalid="isInvalidateFields"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom">
							<legend class="card-inside">{{ FormMSG(20, 'Supplier') }}</legend>
							<div class="pb-4">
								<supplier-selector
									:label="''"
									:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
									:placeholder="FormMSG(22, 'Filter by name')"
									:addlabel="FormMSG(23, 'Add')"
									:update-label="FormMSG(24, 'Update')"
									:supplier-id="supplierId"
									:ocr-suppliers="suppliersInImages"
									@change="handleSupplierChange"
									@supplier:unselected="onRemoveSupplier"
								/>
							</div>
						</fieldset>
					</b-col>
				</b-row>
				<b-row style="padding-bottom: 100px !important">
					<b-col>
						<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend
								:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'} ${
									$screen.width > 576 ? 'text-color-rhapsody-in-blue-2' : ''
								}`"
							>
								{{ FormMSG(168, 'Green details') }}
							</legend>
							<div class="pb-3">
								<co-two-clap
									ref="coTwoClapTicket"
									:parent-id="+expense.id"
									:entity-type="1"
									:for-create="true"
									:external-carbon-data="carbonData"
									hide-location-menu
									hide-premise-menu
									hide-btn-reviewed
									@co-two-clap:change="handleCoTwoClapChange"
								/>
							</div>
						</fieldset>
					</b-col>
				</b-row>
				<b-row class="footer-fixed">
					<b-col cols="12">
						<b-button block size="lg" type="submit" variant="primary" class="mb-2" :disabled="analyzingImage || isImageUploadLoading">
							<b-spinner v-if="isImageUploadLoading" variant="light" label="img loading spinner" />
							{{ FormMSG(21, 'Save') }}
						</b-button>
					</b-col>
					<b-col cols="12" v-if="action === 0">
						<b-button block size="lg" type="button" variant="light" class="mb-2" @click="handleCancel(expense.id)" :disabled="analyzingImage">
							{{ FormMSG(27, 'Cancel') }}
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</div>
		<capture v-if="!$isPwa()" v-model="isCaptureModalOpen" dynamic-crop-size :edit-data="expense" @change="handleImgsTaked" />
		<b-modal
			id="ocr-issue-modal"
			header-class="header-class-modal-doc-package"
			:title="FormMSG(78, 'Error')"
			class="modal-success"
			v-model="ocrIssueModal"
			@ok="ocrIssueModal = false"
			header-bg-variant="danger"
			header-border-variant="danger"
			ok-variant="light"
			ok-only
			modal-class="mui-animation"
			:fade="false"
		>
			{{ FormMSG(77, 'An error has occurred during created the expense, please retry again or contact your administrator.') }}
		</b-modal>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import { isNil, greaterThanZero } from '@/shared/utils';
import { ArrowLeft, Info, PlusCircle, Euro, Percent, XCircle } from 'lucide-vue';
import SupplierSelector from '@/components/SupplierSelector';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import GetPictureFromCameraPwaBtn from '@/components/Packages/Captures/components/GetPictureFromCameraPwaBtn';
import Capture from '@/components/ExpenseService/Capture';
import ocrImageAnalysis from '@/services/ocr.service';
import { expenseItemsFromImageOrPdf } from '@/cruds/expense.crud';
import getVatData from '@/shared/vat-helper';
// import { windowHashChange } from '~helpers';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import CurrencySvg from '@/components/Svg/Currency';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { store } from '@/store';
import { INIT_DATA_CARBON } from '@/shared/constants';
import _ from 'lodash';
import { VALIDATION_TYPE } from '@/shared/constants';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'TicketForm',
	mixins: [languageMessages, validationMixin, globalMixin],
	components: {
		CapturesPackage,
		SupplierSelector,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		GetPictureFromCameraPwaBtn,
		Capture,
		CurrencySvg,
		CoTwoClap,
		Loading
	},
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		users: {
			type: Array,
			required: true
		},
		action: {
			type: Number, // ADD = 0, UPDATE = 1
			required: true,
			default: 0
		},
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		...mapGetters({
			paiementTypes: 'expenses/paiementTypes',
			taxOptions: 'flag/taxOptions'
		}),
		captionTitle() {
			const { type } = this.$route.params;

			if (this.action === 0) {
				if (parseInt(type, 10) === 0) {
					return this.FormMSG(1, 'Add Ticket');
				} else {
					return this.FormMSG(37, 'Add Invoice');
				}
			} else if (this.action === 1) {
				if (parseInt(type, 10) === 0) {
					return this.FormMSG(2, 'Update Ticket');
				} else {
					return this.FormMSG(38, 'Update Invoice');
				}
			}
		},
		optionPaiementTypes() {
			return this.paiementTypes.map(({ id, name }) => {
				return {
					value: id,
					text: this.FormMSG(3 + id, name)
				};
			});
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.expense) || parseInt(this.expense.supplierId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.expense.supplierId;
		},
		isInvalidAmountTotal() {
			if (parseFloat(this.expense.amountTotal) > 0) {
				return parseFloat(this.expense.amountTotal) < parseFloat(this.expense.amountVat);
			}
			return false;
		}
	},
	data() {
		return {
			dateFormatted: '',
			showVat: false,
			expense: {},
			selectedUser: 0,
			isSubmitted: false,
			minLengthDescription: 4,
			isCaptureModalOpen: false,
			analyzingImage: false,
			isNew: false,
			suppliersInImages: [],
			errorOcr: false,
			isInvalid: false,
			isSupplierUpdate: false,
			isImageUploadLoading: false,
			isUpdate: false,
			submitCarbon: false,
			vatType: null,
			originalVatAmount: 0,
			vatAmountDisabled: false,
			vatValueChoosed: null,
			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			ocrIssueModal: false
		};
	},
	created() {
		this.$nextTick(async () => {
			const { action, expenseId } = this.$route.params;
			if (action === 'n') {
				this.isNew = true;
				this.selectedUser = +store.state.myProfile.id;
				this.isUpdate = false;
				await this.getTaxOptions();
			} else if (action === 'u') {
				this.isNew = false;
				this.selectedUser = this.expense.user ? +this.expense.user.id : 0;
				this.isUpdate = true;
				await this.getTaxOptions();
			}
			if (isNil(this.expense) || (this.expense && this.expense.id !== expenseId && action === 'u')) {
				const path = '/myexpenses';
				this.$router.push({ path });
			}
			this.initVat();
		});
		// await windowHashChange();
	},
	methods: {
		...mapActions({
			setNewExpense: 'expenses/setNewExpense',
			deleteExpense: 'expenses/deleteExpense',
			getTaxOptions: 'flag/getTaxOptions'
		}),
		handleCoTwoClapChange({ carbonData }) {
			this.carbonData = carbonData;
		},
		handleCoTwoClapEndSubmit() {
			this.$emit('ticket-form-mobile:submitted', {
				type: 0,
				action: this.action,
				success: true
			});

			this.$router.push(`/myexpenses?type=0&s=${this.action === 0 ? '0' : '1'}`);
		},
		handleUploadImgStatus(status) {
			this.isImageUploadLoading = status;
			this.$emit('upload-image-loading:status', status);
		},
		async handleImgsTaken(images) {
			this.analyzingImage = true;
			this.expense.images = images;

			if (
				+this.expense.validated === VALIDATION_TYPE.NOT_SUBMITTED ||
				+this.expense.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
				+this.expense.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
			) {
				/**
				 * remove ext from xid files..
				 * @type {Array} cleanImgs
				 */
				const cleanImgs = images.map((_) => _.replace(/\.[^\.]*$/, ''));
				const [newExpense] = await expenseItemsFromImageOrPdf(cleanImgs).catch((error) => {
					console.error({ error });
					this.expense.images.pop();
					this.ocrIssueModal = true;
					this.analyzingImage = false;
				});
				// await this.setOcrAnalyses(images);

				this.expense = { ...this.expense, ...newExpense };
			}

			this.analyzingImage = false;
		},
		goBack(success = null) {
			const { back, id } = this.$route.query;
			// const { action } = this.$route.params;
			let path = '/myexpenses';

			if (!isNil(back)) {
				if (back === 'i') {
					if (!isNil(success) && typeof success === 'number') {
						path += `?s=${success}`;
					}
					this.$router.push({ path });
				} else if (back === 'e') {
					path += `/${id}`;
					if (!isNil(success) && typeof success === 'number') {
						path += `?s=${success}`;
					}
					this.$router.push({ path });
				}
			} else {
				if (!isNil(success) && typeof success === 'number') {
					path += `?s=${success}`;
				}
				this.$router.push({ path });
			}
		},
		goForce() {
			let path = '/myexpenses';
			this.$router.push({ path });
		},
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			const isInvalidTicket = this.$refs['coTwoClapTicket'].isInvalid();
			if (this.$v.$invalid || this.isInvalid || isInvalidTicket) {
				return;
			}

			if (new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$', 'g').test(this.expense.date)) {
				this.expense.date += 'T00:00:00Z';
			}

			this.expense = {
				...this.expense,
				amountTotal: parseFloat(this.expense.amountTotal),
				amountVat: parseFloat(this.expense.amountVat)
			};

			if (this.expense.validated === 4 || this.expense.validated === 16) {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(36, 'Are you sure to continue with this change ?'), {
						title: this.FormMSG(35, 'Confirm'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.FormMSG(29, 'YES'),
						cancelTitle: this.FormMSG(30, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;

						this.expense.validated = 0;
						await this.updateExpense();
					})
					.catch((err) => console.log(err));
			} else {
				await this.updateExpense();
			}
		},
		async updateExpense() {
			try {
				// console.log({ expense: this.expense, selectedUser: this.selectedUser });

				this.carbonData.carbonId = +this.expense.carbon.id;
				const payload = {
					id: parseInt(this.expense.id, 10),
					expense: {
						...this.expense,
						carbon: {
							..._.omit(this.carbonData, ['carbonValues', 'department', 'function', 'supplier', 'user'])
						}
					},
					userId: parseInt(this.selectedUser, 10),
					projectFlagItemId: this.expense.flagItemId
				};

				await this.setNewExpense(payload);

				this.$emit('ticket-form-mobile:submitted', {
					type: 0,
					action: this.action,
					success: true
				});

				this.$router.push(`/myexpenses?type=0&s=${this.action === 0 ? '0' : '1'}`);
			} catch (error) {
				console.error({ error });
				await this.deleteExpense(this.expense.id);
				this.errorRedirect(error);
			}
		},
		errorRedirect(e) {
			let path = `/myexpenses`;
			const error = e.message.match(new RegExp('[0-9]{3}', 'g'));
			let code = '0';

			if (!isNil(error) && error.length === 1) {
				code = error[0];
			}

			path += `?ec=${code}&eu=1`;
			this.$router.push({ path });
		},
		handleDepartmentAndCatChange({ category, department, subCategory }) {
			this.expense = {
				...this.expense,
				category,
				department,
				subCategory
			};
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		onContext(ctx) {
			this.dateFormatted = ctx.selectedFormatted;
		},
		addVat() {
			this.showVat = !this.showVat;
		},
		/**
		 * @param {Object} supplier
		 */
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;

			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, name } = supplier;
			this.expense = {
				...this.expense,
				supplierId: parseInt(id, 10),
				supplierName: name
			};
		},
		onRemoveSupplier(payload) {
			this.isSupplierUpdate = false;
			this.expense = {
				...this.expense,
				supplierId: 0,
				supplierName: ''
			};
		},
		handleCancel(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(28, 'Please confirm if you want to reject this expense.'), {
					title: this.FormMSG(35, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(29, 'YES'),
					cancelTitle: this.FormMSG(30, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					noCloseOnBackdrop: true
				})
				.then(async (v) => {
					if (!v) return;
					await this.deleteExpense(id);
				})
				.catch((err) => console.log(err))
				.finally(() => {
					this.$emit('ticket-form-mobile:cancelled', true);
					this.$router.push('/myexpenses');
				});
		},
		async handleImgsTaked(images, imagesData) {
			this.analyzingImage = true;
			try {
				const ocrResults = await ocrImageAnalysis(imagesData);
				const vatNumbers = this.extractVATNumbers(ocrResults);
				const emails = this.extractEmails(ocrResults);
				const trimmedVatNumbers = vatNumbers.map((vatNumber) => vatNumber.replaceAll('.', '').replaceAll(' ', ''));

				const validVatNumbers = [];
				for (const vat of trimmedVatNumbers) {
					const res = await getVatData(vat);
					if (res.valid === 'true') {
						res.vat = vat;
						validVatNumbers.push(res);
					}
				}

				this.suppliersInImages = [].concat(
					validVatNumbers.map((vat) => ({ label: 'VAT', value: vat })),
					emails.map((email) => ({ label: 'EMAIL', value: email }))
				);
				this.errorOcr = false;
				// console.log({ocrResults})
			} catch (e) {
				console.error({ e });
				this.errorOcr = true;
			}

			this.analyzingImage = false;
			this.expense.images = images;
			this.emitChange(this.expense);
		},
		extractRegexByLabel(ocrResults, label) {
			if (!ocrResults) {
				return null;
			}
			const { regexMatches } = ocrResults;
			// console.log("label")
			// console.log(label)
			// console.log(regexMatches)
			const matches = regexMatches.filter((m) => m.label === label);
			const uniques = Array.from(new Set(matches && matches[0].match.map((ma) => ma[0])));
			return uniques;
		},
		extractVATNumbers(ocrResults) {
			return this.extractRegexByLabel(ocrResults, 'VAT');
		},
		extractEmails(ocrResults) {
			return this.extractRegexByLabel(ocrResults, 'EMAIL');
		},
		togglePictureModal() {
			this.isCaptureModalOpen = true;
		},
		emitChange(ticket) {
			this.$emit(
				'change',
				{
					...ticket,
					amountTotal: parseFloat(ticket.amountTotal),
					amountVat: parseFloat(ticket.amountVat)
				},
				this.$v.$invalid
			);
		},
		handleDateInput(e) {
			document.getElementById('ticket-date-picker').click();
		},
		selectableItem(item) {
			return !item.disabled;
		},
		handleVatChange(payload) {
			this.vatValueChoosed = payload;

			if (!_.isNil(payload)) {
				// console.log({ payload, taxOptions: this.taxOptions.filter((tax) => +payload === +tax.item.id && !tax.disabled)[0].item.factor });
				this.calculateVatAmount(payload);
				this.vatAmountDisabled = true;
				this.expense.flagItemId = +payload;
			} else {
				this.expense.amountVat = this.originalVatAmount;
				this.vatAmountDisabled = false;
				this.expense.flagItemId = 0;
			}

			this.$v.$touch();
		},
		initVat(hideOriginal = false) {
			if (_.isNil(this.editData)) return;

			if (!hideOriginal) this.originalVatAmount = this.expense.amountVat;
			this.expense.flagItemId = 0;

			if (
				!_.isUndefined(this.expense.projectFlagItems) &&
				!_.isNil(this.expense.projectFlagItems) &&
				this.expense.projectFlagItems.taxeFlags.length > 0
			) {
				this.vatType = this.expense.flagItemId = this.expense.projectFlagItems.taxeFlags[0].id;
				this.vatAmountDisabled = true;
			}
			setTimeout(() => {
				this.carbonData = {
					..._.omit(this.expense.carbon, ['carbonValues', 'department', 'function', 'supplier', 'user']),
					carbonId: +this.expense.carbon.id
				};
			}, 250);
		},
		handleAmountTotalChange(payload) {
			if (!_.isNil(this.vatType)) {
				this.calculateVatAmount(this.vatType);
			}
		},
		calculateVatAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;

			let amount = this.expense.amountTotal / ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));
			this.expense.amount = amount;

			let amountVat = parseFloat((this.expense.amountTotal - amount).toFixed(2));
			this.expense.amountVat = amountVat;
		}
	},
	validations: {
		expense: {
			description: {
				required,
				min: minLength(4)
			},
			amountTotal: {
				required,
				decimal
			}
		}
	},
	watch: {
		editData: {
			handler(n) {
				if (!isNil(n)) {
					setTimeout(() => {
						this.expense = n;

						if (this.expense.amountTotal === 0) {
							this.expense.amountTotal = '';
						}

						if (isNil(this.expense.images)) {
							this.expense = {
								...this.expense,
								images: []
							};
						}

						this.initVat(true);

						/**
						 * @TODO
						 * Do not find why this bug (regression), need more investigations
						 * But for now let force validated to 0
						 * When value of action is ADD
						 */
						if (this.action === 0) {
							this.expense.validated = 0;
						}
					}, 100);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
#ocr-issue-modal .header-class-modal-doc-package h5 {
	color: #fff;
}
</style>
